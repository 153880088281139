/* eslint-disable no-underscore-dangle */
import intlTelInput from 'intl-tel-input';
import { LitElement } from 'lit';
import { SCREEN_SIZES } from '../constants';
class PhoneInputWrapper extends LitElement {
    constructor() {
        super(...arguments);
        this.phoneInputBindingTimeout = null;
        /* Continually attempts to initialize the iti phone instance after every
        animation frame if the phone instance is in DOM and has a computed
        width. We use a connected callback timeout to prevent infinite loop. */
        this.setPhoneInputInstance = () => {
            var _a;
            if (!this.phoneInputBindingTimeout)
                return;
            if (this.input && this.input.offsetWidth > 0) {
                this.phoneInputInstance = intlTelInput(this.input, {
                    autoPlaceholder: 'off',
                    countrySearch: false,
                    dropdownContainer: document.body,
                    fixDropdownWidth: false,
                    formatAsYouType: false,
                    initialCountry: (_a = this.countryCode) !== null && _a !== void 0 ? _a : 'US',
                    preferredCountries: ['us', 'ca'],
                    showSelectedDialCode: true,
                    useFullscreenPopup: this.viewport === SCREEN_SIZES.MOBILE,
                    utilsScript: 'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/21.2.8/js/utils.js',
                });
                this.phoneInputBindingTimeout = null;
            }
            else {
                requestAnimationFrame(this.setPhoneInputInstance);
            }
        };
    }
    static get properties() {
        return {
            inputId: { type: String },
            countryCode: { type: String },
            phoneInputInstance: { type: Object, state: true },
            finalInputStyleRules: { type: Object },
            viewport: { type: String },
        };
    }
    createRenderRoot() {
        return this;
    }
    getFormattedInputValue() {
        var _a;
        return (_a = this.phoneInputInstance) === null || _a === void 0 ? void 0 : _a.getNumber();
    }
    startPhoneInputBindingTimeout() {
        this.phoneInputBindingTimeout = window.setTimeout(() => {
            this.phoneInputBindingTimeout = null;
        }, 5000);
    }
    updatePhoneInput() {
        requestAnimationFrame(this.setPhoneInputInstance);
    }
    getValidationError() {
        var _a, _b, _c, _d, _e, _f;
        if ((_a = this.phoneInputInstance) === null || _a === void 0 ? void 0 : _a.isValidNumber())
            return null;
        const validationError = (_b = this.phoneInputInstance) === null || _b === void 0 ? void 0 : _b.getValidationError();
        switch (validationError) {
            // eslint-disable-next-line
            // @ts-ignore
            case (_d = (_c = window.intlTelInputUtils) === null || _c === void 0 ? void 0 : _c.validationError) === null || _d === void 0 ? void 0 : _d.TOO_SHORT:
                return 'Phone number is too short';
            // eslint-disable-next-line
            // @ts-ignore
            case (_f = (_e = window.intlTelInputUtils) === null || _e === void 0 ? void 0 : _e.validationError) === null || _f === void 0 ? void 0 : _f.TOO_LONG:
                return 'Phone number is too long';
            default:
                return 'Invalid phone number';
        }
    }
    firstUpdated(_changedProperties) {
        super.firstUpdated(_changedProperties);
        this.updatePhoneInput();
    }
    // The dropdown position breaks if the popup becomes scrollable so we need to force close it on scroll
    // See https://github.com/jackocnr/intl-tel-input?tab=readme-ov-file#troubleshooting for more details
    static closeDropdownOnScroll() {
        window.dispatchEvent(new Event('scroll', {
            cancelable: true,
            bubbles: true,
        }));
    }
    /*
    intl-tel-input modifies the dom after it initializes and needs to apply some inline styles
    to the phone input to get the country dropdown sized appropriately. Notably it sets a margin-bottom: 0 !important
    which conflicts with user specified styles. The two functions below will copy the styles from the input to the
    render root to ensure that the styles are applied as specified by the user.
    */
    copyInputBlockFinalStyleRules() {
        const renderRoot = this.renderRoot;
        const { margin } = this.finalInputStyleRules;
        renderRoot.style.margin = margin;
    }
    copyInputComputedStyle() {
        if (!this.input)
            return null;
        this.renderRoot.style.margin = getComputedStyle(this.input).margin;
    }
    // The any is not ideal here but is needed to fix conflicts with differing SDK and FE versions of lit
    updated(_changedProperties) {
        super.updated(_changedProperties);
        this.copyInputBlockFinalStyleRules();
    }
    connectedCallback() {
        var _a;
        super.connectedCallback();
        this.startPhoneInputBindingTimeout();
        this.input = this.renderRoot.querySelector(`.${this.inputId}`);
        this.copyInputComputedStyle();
        (_a = document
            .querySelector('form')) === null || _a === void 0 ? void 0 : _a.addEventListener('scroll', PhoneInputWrapper.closeDropdownOnScroll);
    }
    disconnectedCallback() {
        var _a, _b;
        super.disconnectedCallback();
        (_a = this.phoneInputInstance) === null || _a === void 0 ? void 0 : _a.destroy();
        (_b = document
            .querySelector('form')) === null || _b === void 0 ? void 0 : _b.removeEventListener('scroll', PhoneInputWrapper.closeDropdownOnScroll);
        if (this.phoneInputBindingTimeout)
            clearTimeout(this.phoneInputBindingTimeout);
    }
}
if (!customElements.get('ps-phone-input-block-iti-wrapper')) {
    customElements.define('ps-phone-input-block-iti-wrapper', PhoneInputWrapper);
}
