import { nanoid } from 'nanoid';
import {
  setCookie,
  fetchWithTimeout,
  API_GATEWAY_BASE_URL,
} from '../../helpers/utility';
import {
  BIS_POPUP_RENDERED_MESSAGE,
  PS_POPUP_IFRAME_ID,
  BIS_VARIANT_COOKIE_ID_PREFIX,
} from '../../helpers/constants';

/**
 * @param {string} shopId
 * @param {string} phoneNumber
 * @param {string} subscriberId
 * @returns {Promise}
 */
export const postBackInStockSubscribe = async (
  shopId,
  phoneNumber,
  subscriberId,
  variantId,
) => {
  try {
    const data = {
      product_url: window.location.href,
      shop_id: shopId,
      variant_id: variantId,
      ...(phoneNumber
        ? { phone_number: phoneNumber }
        : { subscriber_id: subscriberId }),
    };
    const response = await fetchWithTimeout(
      `${API_GATEWAY_BASE_URL}/v2/back_in_stock/subscribe`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: JSON.stringify(data),
      },
      15000,
    );
    return response.ok ? response.json() : null;
  } catch {
    return null;
  }
};

/**
 * @param {string} shopId
 * @param {string} variantId
 * @returns {Promise<import('./back-in-stock').ProductStock>}
 */
export const fetchProductStock = async (shopId, variantId) => {
  try {
    const response = await fetch(
      `${API_GATEWAY_BASE_URL}/v2/platforms/shopify/product_stock/${shopId}/${variantId}`,
      {
        referrerPolicy: 'no-referrer-when-downgrade',
      },
    );
    return response.ok ? response.json() : null;
  } catch {
    return null;
  }
};

/**
 * @param {string} shopId
 * @returns {Promise<import('./widget/widget').Settings>}
 */
export const fetchSettings = async (shopId) => {
  try {
    const response = await fetch(
      `${API_GATEWAY_BASE_URL}/v2/back_in_stock/style/${shopId}`,
    );
    return response.ok ? response.json() : null;
  } catch {
    return null;
  }
};

/**
 * @param {string} variantId
 * @returns {string}
 */
export const getVariantCookieName = (variantId) =>
  `${BIS_VARIANT_COOKIE_ID_PREFIX}${variantId}`;

/** @param {string} variantCookieName */
export const createBackInStockVariantCookie = (variantCookieName) => {
  setCookie(variantCookieName, nanoid(), 3650);
};

/** @param {string} variantCookieName */
export const removeBackInStockVariantCookie = (variantCookieName) => {
  setCookie(variantCookieName, '', -3650);
};

/* Widget Utils */
/** @param {string} nodeId */
export const removeNode = (nodeId) => {
  const node = document.getElementById(nodeId);
  if (node) {
    node.remove();
  }
};

/**
 * @param {string} rawCss
 * @param {string} selector
 */
export const generateStyleElement = (rawCss, selector) => {
  removeNode(selector);

  const styles = document.createElement('style');
  styles.id = selector;
  styles.appendChild(document.createTextNode(rawCss));
  document.head.appendChild(styles);
};

/** @param {string} selector */
export const addBundledStyles = (selector) => {
  removeNode(selector);

  const link = document.createElement('link');
  link.id = selector;
  link.rel = 'stylesheet';
  link.type = 'text/css';
  link.href = `${process.env.HOSTNAME}/sdk.css`;
  document.head.appendChild(link);
};

export const forceIframeClose = () => {
  document
    .getElementById(PS_POPUP_IFRAME_ID)
    ?.contentWindow?.postMessage(BIS_POPUP_RENDERED_MESSAGE, '*');
};

/**
 * @param {string} selector
 * @param {(node) => Node | null} callbackLogic
 * @returns {Node | null}
 */
export const getAddToCartElement = (selector, callbackLogic) => {
  const addToCart = document.querySelectorAll(selector);
  if (addToCart.length >= 1) {
    return callbackLogic
      ? callbackLogic(addToCart[0])
      : addToCart[0].parentNode;
  }

  return null;
};

/**
 * @param {Node} buttonNode
 * @returns {Node | null}
 */
export const checkNoNameAttributeButton = (buttonNode) => {
  const ALLOWED_TEXT = ['sold out', 'out of stock', 'unavailable'];
  const textContent = buttonNode.textContent.trim().toLowerCase();
  return ALLOWED_TEXT.includes(textContent) ? buttonNode.parentNode : null;
};

export const resolveSettings = (settings, popupPreferences) => ({
  bannerDesktopColor:
    settings.bannerDesktopColor || popupPreferences.buttonBackgroundColor,
  bannerFontSize: settings.bannerFontSize
    ? `${settings.bannerFontSize}px`
    : 'inherit',
  bannerFontWeight: settings.fontWeight || 'bold',
  bannerMobileColor:
    settings.bannerMobileColor || popupPreferences.buttonBackgroundColor,
  bannerTextColor: settings.bannerTextColor || popupPreferences.buttonTextColor,
  callToActionBannerText:
    settings.callToActionBannerText || 'Text me if back in stock',
  callToActionLinkText:
    settings.callToActionLinkText ||
    'Notify me when this item is back in stock!',
  linkColor: settings.linkColor || 'inherit',
  linkDecoration:
    settings.linkDecoration == null || settings.linkDecoration
      ? 'underline'
      : 'inherit',
  linkDecorationColor: settings.linkDecorationColor || 'inherit',
  linkDecorationStyle: settings.linkDecorationStyle || 'inherit',
  linkFontSize: settings.linkFontSize
    ? `${settings.linkFontSize}px`
    : 'inherit',
  linkFontWeight: settings.linkFontWeight || 'inherit',
  linkHoverColor: settings.linkHoverColor || 'inherit',
  forceBanner: settings.forceBanner || false,
});
