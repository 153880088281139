import { EVENT_NAME } from './constants';

interface PopupImpressionEvent {
  type: 'impression';
  popupId: number;
  popupName: string;
  isReopen: boolean;
}

interface PopupSubmitEvent {
  type: 'formSubmit';
  popupId: number;
  popupName: string;
  values: Record<string, string | number>;
}

interface PopupCloseEvent {
  type: 'close';
  popupId: number;
  popupName: string;
  hard: boolean;
}

export const publishPopupImpressionEvent = (
  popupEventData: Omit<PopupImpressionEvent, 'type'>,
) => {
  window.dispatchEvent(
    new CustomEvent<PopupImpressionEvent>(EVENT_NAME, {
      detail: {
        type: 'impression',
        popupId: popupEventData.popupId,
        popupName: popupEventData.popupName,
        isReopen: popupEventData.isReopen,
      },
      bubbles: true,
      cancelable: true,
    }),
  );
};

export const publishPopupSubmitEvent = (
  popupEventData: Omit<PopupSubmitEvent, 'type'>,
) => {
  window.dispatchEvent(
    new CustomEvent<PopupSubmitEvent>(EVENT_NAME, {
      detail: {
        type: 'formSubmit',
        popupId: popupEventData.popupId,
        popupName: popupEventData.popupName,
        values: popupEventData.values,
      },
      bubbles: true,
      cancelable: true,
    }),
  );
};

export const publishPopupCloseEvent = (
  popupEventData: Omit<PopupCloseEvent, 'type'>,
) => {
  window.dispatchEvent(
    new CustomEvent<PopupCloseEvent>(EVENT_NAME, {
      detail: {
        type: 'close',
        popupId: popupEventData.popupId,
        popupName: popupEventData.popupName,
        hard: popupEventData.hard,
      },
      bubbles: true,
      cancelable: true,
    }),
  );
};
