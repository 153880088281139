import dynamicMobileStyles from './dynamicMobileStyles';

/**
 * @param {import("../../widget").PopupPreferences} props
 */

// TODO(Kameron Ahler): 100vh will overflow on mobile
const mobileStyles = (props) => `
    #ps-bis-widget-bubble__container.bubble__container {
        top: initial;
        left: 0px;
        bottom: 0px;
        width: 100%;
        height: initial;
        display: initial;
        z-index: 2147483647;
    }

    #ps-bis-widget-bubble__container .bubble__close-container {
        width: 100%;
        position: static;
        text-align: center;
        writing-mode: initial;
        text-orientation: initial;
    }

    #ps-bis-widget-bubble__close-text {
        font-size: 22px;
    }

    #ps-bis-widget-bubble__close-widget {
        top: 15px;
        padding: 4px;
        transform: translate(15%, -50%);
        border-radius: 50%;
        width: 50px;
        height: 30px;
        right: 0px;
        left: unset;
        background: unset;
    }

    #ps-bis-widget-mobile-modal__container {
        border: none; 
        position: fixed; 
        visibility: visible; 
        z-index: 2147483647; 
        max-height: 100vh; 
        max-width: 100vw; 
        transition: none 0s ease 0s; 
        background: none transparent; 
        opacity: 1; 
        height: 82vw; 
        width: 94vw; 
        inset: auto auto 3vw 3vw; 
        display: block;         
    }

    #ps-bis-widget-mobile-modal__container ::placeholder {
        opacity: .5;
    }     

    /* Styles from PS popup, but with some changes here and there */

    #ps-bis-widget-mobile-modal__container .hidden {
        visibility: hidden;
    }

    #ps-bis-widget-mobile-modal__container main a {
        color: inherit;
        text-decoration: none;
    }

    /*overlay*/

    #ps-mobile-widget-overlay__container {
        position: fixed;
        height: 100vh;
        width: 100vw;
        top: 0;
        left: 0;
        bottom: auto;
        right: auto;
        background: #ecf0f1;
        padding: 0;
        padding-top: 10px;
        margin: 0;
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
        display:block;
    }

    #ps-mobile-widget-overlay__close-container {
        text-align: right;
        position: absolute;
        top: 0;
        right: 0;
    }

    #ps-mobile-widget-overlay__close-button {
        display: inline-block;
        padding: 10px 20px;
        font-size: 3em;
        line-height: 1;
    }

    #ps-mobile-widget-overlay__logo-container {
        text-align: center;
        height: 15vh;
    }

    #ps-mobile-widget-overlay__logo-image {
        max-height: 11vh;
        max-width: 40vw;
    }

    #ps-mobile-widget-overlay__header-container {
        text-align: center;
        padding: 0px 10vw;
    }

    #ps-mobile-widget-overlay__header {
        text-align: center;
    }

    #ps-mobile-widget-overlay__terms-container {
        text-align: center;
        padding: 20px 5vw;
    }

    #ps-mobile-widget-overlay__terms {
        font-size: 3vw;
        line-height: 1.2;
    }

    #ps-mobile-widget-overlay__headline {
        font-size: 20vw;
        margin: 0;
        padding: 0;
        font-weight: bold;
        line-height: 1.2;
    }

    #ps-mobile-widget-overlay__pre-headline,
    #ps-mobile-widget-overlay__post-headline {
        font-size: 8vw;
        margin: 0;
        padding: 0;
        line-height: 1;
        font-weight: 300;
    }

    #ps-mobile-widget-overlay__buttons-container {
        text-align: center;
        padding: 0px 10vw;
    }

    #ps-mobile-widget-overlay__buttons-container--opt-in {
        text-align: center;
        padding: 0px 10vw;
    }

    #ps-mobile-widget-overlay__buttons {
        text-align: center;
    }

    #ps-mobile-widget-overlay__accept-button {
        max-width: 100%;
        display: block;
        padding: 20px 15px;
        margin: 20px 0px;
        font-size: 7vw;
        font-weight: bold;
        text-decoration: none;
        border-radius: 10px;
    }

    .phone #ps-mobile-widget-overlay__accept-button {
        margin-bottom: 0;
    }

    .phone #ps-mobile-widget-overlay__phone-input-wrapper > .iti {
        padding: 20px 15px;
    }

    #ps-mobile-widget-overlay__accept-button {
        border: 3px solid ;
        color: white;
        width: 100%;
    }

    #ps__overlay_mobile_icon {
        font-size: 13vw;
        margin: -3vw 15px -3vw 0;
        position: relative;
        top: 2vw;
    }

    /*midsize*/
    #ps-mobile-widget-partial__container {
        padding: 0;
        position: absolute;
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
        bottom: 0;
        left: 0;
        box-shadow: 0 0 5px 0 rgba(0,0,0,0.3);
        display: block;
    }

    #ps-mobile-widget-partial__close-button {
        text-align: center;
        width: 30px;
        height: 30px;
        line-height: 1;
        font-size: 30px;
        display: inline-block;
    }

    #ps-mobile-widget-partial__logo-container {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    #ps-mobile-widget-partial__logo-image {
        max-height: 14vh;
        max-width: 40vw;
        margin: 0.5rem auto;
    }

    #ps-mobile-widget-partial__header-container {
        text-align: center;
        padding: 0px 5vw;
    }

    #ps-mobile-widget-partial__header {
        text-align: center;
        padding-top: 20px;
    }

    #ps-mobile-widget-partial__success-headline,
    #ps-mobile-widget-partial__headline {
        font-size: 15vw;
        margin: 0;
        padding: 0;
        font-weight: bold;
        line-height: 1;
    }

    #ps-mobile-widget-partial__success-pre-headline {
        margin-bottom: 0.5rem;
    }

    #ps-mobile-widget-partial__success-pre-headline,
    #ps-mobile-widget-partial__success-post-headline {
        font-size: 6vw;
        margin-top: 10px;
        padding: 0;
        font-weight: 300;
        line-height: 1;
    }

    #ps-mobile-widget-partial__buttons-container {
        text-align: center;
        padding: 18px 10vw;
        padding-top: 0;
    }

    #ps-mobile-widget-partial__buttons {
        text-align: center;
    }

    #ps-mobile-widget-partial__accept-button {
        border: 3px solid;
        color: white;
        width: 100%;
        display: block;
        padding: 12px 10px;
        margin: 2vh 0;
        font-size: 7vw;
        font-weight: bold;
        text-decoration: none;
        border-radius: 10px;
    }

    #ps__midsize_mobile_icon {
        font-size: 13vw;
        margin: -3vw 15px -3vw 0;
        position: relative;
        top: 2vw;
    }

    #ps-mobile-widget-partial__terms {
        text-align: center;
        width: 100%;
        padding: 7px;
        font-size: 3vw;
        line-height: 1.1;
        letter-spacing: initial;
    }

    #ps-bis-widget-mobile-modal__container #ps-mobile-widget-overlay__reject-button {
        display: none;
    }

    /*no landscape*/

    @media screen and (orientation:landscape) {
        #ps-mobile-widget-overlay__container {
            display: none;
        }
    }

    /* Close Button */

    #ps-mobile-widget-close__body {
        padding: 8px 12px;
        color: #fff;
        font-size: 22px;
        border-radius: 10px;
        font-weight: 700;
        text-align: center;
        bottom: 10px;
        left: 14px;
        font-weight: bold;
        position: absolute;
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3);
        cursor: pointer;
    }

    #ps-mobile-widget-close__body > div {
        position: initial;
        width: 100%;
        height: 100%;
    }

    #ps-mobile-widget-close__button {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        padding: 4px;
        transform: translate(-50%, -50%);
        fill: #212529;
        text-align: center;
        width: 23px;
        height: 23px;
        background: hsla(0,0%,100%,.8);
        color: #212529;
        font-size: 25px;
        line-height: .9;
        border-radius: 50%;
        position: absolute;
        top: 0;
        left: 0;
    }

    #ps-mobile-widget-close__button > svg {
        width: 100%;
        height: 100%;
    }

    #ps-mobile-widget-overlay__phone-input,
    #ps-mobile-widget-partial__phone-input {
        font-size: 22px;
        width: 100%;
        background-color: rgba(0, 0, 0, 0);
        padding: 8px;
        padding-top: 6px;
        padding-left: 76px !important;
        width: 100%;
        outline: 0;
        border: 0;
        box-shadow: none;
    }

    #ps-mobile-widget-partial__form-wrapper {
        padding: 45px 16px 25px 16px;
        display:flex;
        justify-content:space-between;
    }

    #ps-mobile-widget-partial__form-wrapper.ps-subscribed {
        padding-top: 0px;
        display: inherit;
    }

    #ps-mobile-widget-overlay__phone-input-wrapper > .iti {
        padding: 8px;
    }

    #ps-mobile-widget-overlay__phone-input-wrapper > .iti,
    #ps-mobile-widget-partial__phone-input-wrapper > .iti {
        width: 100%;
        background-color: rgba(255,255,255,.3);
        font-size: 5vw;
        font-weight: bold;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
        border-style: solid;
        border-width: 2px;
        font-weight: bold;
    }

    #ps-mobile-widget-overlay__phone-input-wrapper > .iti--separate-dial-code .iti__selected-flag,
    #ps-mobile-widget-partial__phone-input-wrapper > .iti--separate-dial-code .iti__selected-flag {
        background-color: rgba(0, 0, 0, 0);
    }

    #ps-mobile-widget-partial__phone-submit {
        padding: 7px;
        color: #fff;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        font-weight: bold;
        border: none;
    }

    #ps-mobile-widget-partial__phone-submit > svg {
        width: 24px;
    }

    #ps-mobile-widget-partial__accept-button-text {
        white-space: nowrap;
    }

    #ps-bis-widget-mobile-modal__container div:empty {
        display: inherit;
    }

    #ps-bis-widget-mobile-modal__container .iti {
        width: 100%;
    }

    #ps-bis-widget-mobile-modal__container .iti--separate-dial-code .iti__selected-flag {
        background-color: rgba(0, 0, 0, 0);
    }

    #ps-mobile-widget-overlay__terms-container.ps-hide-visibility,
    #ps-mobile-widget-overlay__phone-input-wrapper.ps-hide-visibility {
        visibility: hidden;
    }

    #ps__bis_container_root +.iti.iti--container {
        z-index: 2147483647;
    }

    ${dynamicMobileStyles(props)}
`;

export default mobileStyles;
