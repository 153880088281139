/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { TOKEN_COOKIE_NAME } from '../../helpers/constants';
import { canUserBeTracked } from '../../helpers/customerPrivacy';
import { getCookie } from '../../helpers/utility';
import { fetchProductStock } from '../back-in-stock/utils';
import {
  getThirdPartyIdentifiers,
  isIdentifiedByThirdPartyCookie,
} from '../identifiers/thirdPartyIdentifiers';

export interface DisclaimerLink {
  text: string;
  url: string;
}

export interface Disclaimer {
  text: string;
  terms_of_service: DisclaimerLink;
  privacy_policy: DisclaimerLink;
}

export interface SdkConfig {
  data: {
    ba_automation_enabled: boolean;
    back_in_stock_automation_enabled: boolean;
    flags: string[];
    shop_shop: string;
    disclaimer: Disclaimer;
  };
}

interface Product {
  resource_id: string;
  variant_id: string;
  name: string;
  price_in_cents: number | null;
  sku: string;
  vendor: string;
  category: string;
  resource_type: string;
}

export interface PageViewEvent {
  url: string;
  page_type: string;
  referrer: string;
  resource?: Product;
  search_params: { [x: string]: string } | null;
  shop_id: string;
}

const getVariantIdFromQueryString = (search: string): string | null =>
  new URLSearchParams(search).get('variant');

// Some themes have the product info split across multiple forms
export const getAddToCartForms = (): NodeListOf<HTMLFormElement> =>
  document.querySelectorAll('form[action="/cart/add"]');

export const PageTypes = {
  Product: 'product',
  Search: 'search',
};

const ResourceTypes = {
  Product: 'product',
};

export const getVariantIdFromAddToCartForm = (
  productVariants: any[],
): string | null => {
  const addToCartForms = getAddToCartForms();
  if (addToCartForms.length === 0) {
    return null;
  }

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < addToCartForms.length; i++) {
    const partialFormData = new FormData(addToCartForms[i]);
    const variantId = partialFormData.get('id');
    if (
      variantId &&
      productVariants.find((variant) => variant.id.toString() === variantId)
    ) {
      return variantId.toString();
    }
  }

  return null;
};

export const getProduct = () =>
  window.psConfig?.productTrackingData ?? window.meta?.product;

export const getVariantId = (product: any, search: string): string =>
  getVariantIdFromQueryString(search) ??
  getVariantIdFromAddToCartForm(product.variants) ??
  product.variants[0].id.toString();

export const getSearchParameters = (
  search: string,
): { [x: string]: string } | null => {
  const searchParams = Array.from(new URLSearchParams(search));
  if (searchParams.length === 0) {
    return null;
  }

  return searchParams.reduce(
    (previous, current) => ({
      ...previous,
      [current[0]]: current[1],
    }),
    {},
  );
};

export const getSelectedProductInfo = (
  product: any,
  search: string,
): Product => {
  let variant = product.variants[0];
  if (product.variants.length > 1) {
    const variantId = getVariantId(product, search);
    variant = product.variants.find((v: any) => v.id.toString() === variantId);
  }

  return {
    category: product.type,
    name: variant.name,
    price_in_cents: Number.isNaN(+variant.price) ? null : +variant.price,
    resource_id: product.id || product.productId,
    resource_type: ResourceTypes.Product,
    sku: variant.sku,
    variant_id: variant.id,
    vendor: product.vendor,
  };
};

export const mapShopifyDataToProductPageEvent = (
  shopId: string,
): PageViewEvent => ({
  shop_id: shopId,
  url: window.location.href,
  search_params: getSearchParameters(window.location.search),
  page_type: PageTypes.Product,
  referrer: document.referrer,
  resource: getSelectedProductInfo(getProduct(), window.location.search),
});

export const isCurrentlySelectedProductFondue = (): boolean => {
  const product = getProduct();
  const { vendor, sku, category } = getSelectedProductInfo(
    product,
    window.location.search,
  );
  // These criteria are organized by most to least effective.
  // Vendor could be changed by the merchant, whereas the product_type cannot.
  if (
    category?.includes('Fondue Cashback') ||
    sku?.includes('fondue-cashback-sku') ||
    vendor === 'Fondue'
  ) {
    return true;
  }
  return false;
};

export const isCurrentlySelectedProductInStock = async (
  shopId: string,
): Promise<boolean> => {
  const product = getProduct();
  const variantId = getSelectedProductInfo(
    product,
    window.location.search,
  ).variant_id;
  const variantStock = await fetchProductStock(shopId, variantId);
  return !!variantStock?.stock;
};

export const shouldTrackProductPageEvents = async (
  allowThirdPartyIds = false,
): Promise<boolean> => {
  const canTrackUserData = await canUserBeTracked(window.Shopify);
  const isProductPage = !!getProduct()?.id || !!getProduct()?.productId;
  const hasThirdPartyId =
    allowThirdPartyIds &&
    isIdentifiedByThirdPartyCookie(getThirdPartyIdentifiers());

  const canUserBeIdentified =
    !!window.Postscript.getSubscriberId() ||
    !!getCookie(TOKEN_COOKIE_NAME) ||
    hasThirdPartyId;

  if (!isProductPage || !canTrackUserData || !canUserBeIdentified) {
    return false;
  }

  return true;
};
