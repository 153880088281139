/* eslint-disable eqeqeq */
/* eslint-disable prefer-destructuring */
import { captureException, captureEvent } from '@sentry/core';
import {
  QUESTION_OPT_IN_METHODS,
  QUESTION_TYPES,
} from '../widget/forms/constants';
import {
  FONT_NAME,
  PHONE_COLLECTION_ATTRIBUTE,
  SUBSCRIBER_ID_COOKIE_NAME,
  SUCCESS_SCREEN,
  TWOTAP_ID_COOKIE_NAME,
} from './constants';
import { getOptInMethod } from '../widget/helpers/optInMethods';
import { DEFAULT_MOBILE_FONT_FAMILY } from '../services/popup/constants';

const WebFont = require('webfontloader');

const CANARY_PERCENTAGE = 50;

const SYSTEM_FONTS = [FONT_NAME.Arial, FONT_NAME.TimesNewRoman];

export const isInCanary = () => Math.random() * 100 <= CANARY_PERCENTAGE;

export const getApiGatewayBaseUrl = () => process.env.API_GATEWAY_URL;

export const API_GATEWAY_BASE_URL = getApiGatewayBaseUrl();

export const WEBHOOK_URL = process.env.WEBHOOK_URL;

export const delay = (delayMs) =>
  new Promise((resolve) => {
    setTimeout(resolve, delayMs);
  });

// Removes extra whitespace and trailing commas from string.
export const trimString = (str) => {
  if (!str) return str;
  return str.trim().replace(/,*$/, '');
};

export const checkCountry = async () => {
  try {
    const response = await fetch('https://location.postscript.io/country');

    if (!response.ok) {
      throw new Error(`Fetch failed with status code ${response.status}.`);
    }
    const data = await response.json();

    return data.country;
  } catch (originalError) {
    captureException(
      new TypeError(
        'Failed to fetch country. User will be treated as outside of US & CA.',
        { cause: originalError },
      ),
    );
    return null;
  }
};

export const checkCountryAndLogToSentry = async (shopId) => {
  const country = await checkCountry();

  captureEvent({
    message: `Location service fallback used for country lookup`,
    tags: {
      shop_id: shopId,
    },
  });

  return country;
};

export const getPhoneNumberFromCountry = (
  currentCountry,
  shortCode,
  phoneNumberDictionary = {},
) => {
  const countryPhoneMap = {
    US: shortCode,
    AU: phoneNumberDictionary.au_long_code,
    CA: phoneNumberDictionary.ca_long_code,
  };

  return countryPhoneMap[currentCountry] || phoneNumberDictionary.us_long_code;
};

export const preloadImages = (imageUrlList = []) => {
  imageUrlList
    .filter((imageUrl) => !!imageUrl)
    .forEach((imageUrl) => {
      // Preload using link rel tag
      const link = document.createElement('link');
      link.rel = 'preload';
      link.as = 'image';
      link.href = imageUrl;
      document.head.appendChild(link);
    });
};

const appendDefaultWeightsToFontFamily = (font) => {
  // Open Sans Condensed is no longer available on Google Fonts with the default weight (400)
  // but we can still access it if we specifically request the 300 and 700 weights
  if (font.includes(DEFAULT_MOBILE_FONT_FAMILY)) {
    return `${DEFAULT_MOBILE_FONT_FAMILY}:300,700`;
  }

  return font;
};

export const loadFont = (font) => {
  if (!font) return;

  if (SYSTEM_FONTS.includes(font)) return;

  WebFont.load({
    google: {
      families: [appendDefaultWeightsToFontFamily(font)],
    },
  });
};

export const generateScreensList = (popup) => {
  const optInMethod = getOptInMethod(popup);
  const lastScreen = [...popup.collectionAttributes].pop();
  const customPage = popup.pages?.find((p) => lastScreen === `page_${p.id}`);
  const isLegacyPhoneStepLast = lastScreen === PHONE_COLLECTION_ATTRIBUTE;
  const isNewPhoneStepLast = customPage?.questions.some(
    (q) => q.type === QUESTION_TYPES.PHONE,
  );

  const shouldAddSuccessScreen =
    optInMethod === QUESTION_OPT_IN_METHODS.DOUBLE_OPT_IN &&
    (isLegacyPhoneStepLast || isNewPhoneStepLast);

  return [
    ...popup.collectionAttributes,
    ...(shouldAddSuccessScreen ? [SUCCESS_SCREEN] : []),
  ];
};

// Checks whether we want to show/hide popups based on include/exclude settings in PS app
export const checkPageIncluded = (url, includedPages, excludedPages) => {
  if (!url || url.length === 0) return true;
  let pageIncluded = true;

  // Ensures there are no trailing commas or empty strings.
  const includedPagesTrimmed = trimString(includedPages);
  const excludedPagesTrimmed = trimString(excludedPages);

  if (includedPagesTrimmed && includedPagesTrimmed.length > 0) {
    pageIncluded = false;
    const includedArray = includedPagesTrimmed.split(',');
    pageIncluded = includedArray.some((includedPathString) =>
      url.includes(includedPathString),
    );
  }

  if (excludedPagesTrimmed && excludedPagesTrimmed.length > 0) {
    const excludedArray = excludedPagesTrimmed.split(',');

    pageIncluded = excludedArray.some((excludedPathString) =>
      url.includes(excludedPathString),
    )
      ? false
      : pageIncluded;
  }

  return pageIncluded;
};

export const outerWidth = (el) => {
  let width = el.offsetWidth;
  const style = getComputedStyle(el);

  width += parseInt(style.marginLeft, 10) + parseInt(style.marginRight, 10);
  return width;
};

export const outerHeight = (el) => {
  let height = el.offsetHeight;
  const style = getComputedStyle(el);

  height += parseInt(style.marginTop, 10) + parseInt(style.marginBottom, 10);
  return height;
};

export const calculateElementSize = (elementId) => {
  const el = document.getElementById(elementId);

  if (!el) return {};

  return {
    height: outerHeight(el),
    width: outerWidth(el),
  };
};

export const getValidCountryNumber = (
  currentCountry,
  shortCode,
  phoneNumberDict,
) => {
  const number = getPhoneNumberFromCountry(
    currentCountry,
    shortCode,
    phoneNumberDict,
  );

  if (number === false) {
    return false;
  }

  return number;
};

export const extractHostname = (url) => {
  let hostname;

  // find & remove protocol (http, ftp, etc.) and get hostname
  if (url.indexOf('//') > -1) {
    hostname = url.split('/')[2];
  } else {
    hostname = url.split('/')[0];
  }

  // find & remove port number
  hostname = hostname.split(':')[0];
  // find & remove "?"
  hostname = hostname.split('?')[0];

  return hostname;
};

export const extractRootDomain = (domain) => {
  const parts = domain.split('.');

  let rootDomain = domain;

  // If we have a subdomain
  if (parts.length > 2) {
    if (['com', 'co', 'org', 'net'].includes(parts[parts.length - 2])) {
      // If the second to last part is a common TLD (ex. co.uk, com.au, org.uk) we use the last three parts
      // Note: This would actually be a problem if we got a shop whose domain was something like "www.org.com"
      rootDomain = parts.slice(parts.length - 3).join('.');
    } else {
      // Otherwise we use the last two parts
      rootDomain = parts.slice(parts.length - 2).join('.');
    }
  }
  return rootDomain;
};

export const setCookie = (
  name,
  value,
  days,
  hours = 0,
  allowSubdomains = false,
) => {
  let cookieStr = `${name}=${value}`;

  if (days || hours) {
    const date = new Date();
    date.setTime(
      date.getTime() + days * 24 * 60 * 60 * 1000 + hours * 60 * 60 * 1000,
    );
    cookieStr += `; expires=${date.toUTCString()}`;
  }

  if (allowSubdomains) {
    cookieStr += `; domain=${extractRootDomain(window.location.hostname)}`;
  }

  cookieStr += '; path=/';

  document.cookie = cookieStr;
};

export const getCookie = (name) => {
  const nameEQ = `${name}=`;
  const ca = window.parent.document.cookie.split(';');
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    // eslint-disable-next-line eqeqeq
    while (c.charAt(0) == ' ') c = c.substring(1, c.length);
    // eslint-disable-next-line eqeqeq
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
};

/**
 * Gets a map of cookies that match the popup cookie format. E.G
 * any cookies matching the _ps_pop_123 format.
 *
 * @returns {object} - map of popup cookie key/value pairs
 */
export const getAllCookiesByPrefix = (cookiePrefix) => {
  const cookies = window.parent.document.cookie.split(';');

  return cookies
    .map((cookie) => cookie.trim().split('='))
    .filter((cookiePair) => cookiePair[0].startsWith(cookiePrefix))
    .reduce((agg, item) => {
      const [key, value] = item;
      return { ...agg, [key]: value };
    }, {});
};

export const fetchWithTimeout = (url, options = {}, timeoutMs = 10000) => {
  const controller = new AbortController();
  const abortSignal = controller.signal;
  const abortTimeout = setTimeout(() => {
    controller.abort();
  }, timeoutMs);

  return fetch(url, {
    ...options,
    signal: abortSignal,
  }).then((response) => {
    clearTimeout(abortTimeout);
    return response;
  });
};

export const pollSubscriberIfNotIdentified = async () => {
  const canUserBeIdentified = !!window.Postscript.getSubscriberId();
  const twoTapId = getCookie(TWOTAP_ID_COOKIE_NAME);

  if (twoTapId && !canUserBeIdentified) {
    const data = { server_id: twoTapId };
    try {
      const response = await fetch(`${WEBHOOK_URL}/v1/lookup_subscriber`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: JSON.stringify(data),
      });

      const body = await response.json();
      // eslint-disable-next-line
      if (body?.message?.subscriber_id) {
        setCookie(SUBSCRIBER_ID_COOKIE_NAME, body.message.subscriber_id, 3650);
      }
    } catch {
      // ignore errors
    }
  }
};

export const identifyAndLogHeadlessShops = (shopId, script) => {
  const HEADLESS_LOG_LOCALSTORAGE_KEY = 'ps_headless_log';
  const hasPreviouslyLoggedStore = !!localStorage.getItem(
    HEADLESS_LOG_LOCALSTORAGE_KEY,
  );
  // For standard shopify sites created after 12/8/2020 we inject a script tag in their theme with the id set to ps-sdk-loader.
  // Our docs for headless shops don't instruct users to add that same id, so if the id is missing and the Shopify global is missing we can assume the shop is headless.
  const isHeadlessShop =
    script.src.toLowerCase().includes('sdk.bundle.js') &&
    script.id !== 'ps-sdk-loader' &&
    !window.Shopify;

  if (!isHeadlessShop || hasPreviouslyLoggedStore) {
    return;
  }

  fetch(`${API_GATEWAY_BASE_URL}/sdk/logging/${shopId}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    body: JSON.stringify({
      domain: `${window.location.origin}${window.location.pathname}`,
    }),
  });

  localStorage.setItem(HEADLESS_LOG_LOCALSTORAGE_KEY, true);
};

export const isDesktop = () =>
  !window.matchMedia('(min-device-width : 300px)').matches ||
  !window.matchMedia('(max-device-width : 480px)').matches;
