/* eslint-disable no-param-reassign */
import { html, nothing, svg } from 'lit';
import { classMap } from 'lit/directives/class-map.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import renderEditorNode from '../development/editorNode';
import { BLOCK_STYLE_ELEMENT, SPIN_TO_WIN_INNER_CIRCLE_ELEMENT, SPIN_TO_WIN_LINE_ELEMENT, SPIN_TO_WIN_OUTER_CIRCLE_ELEMENT, STYLE_RULE_NAMES, } from '../constants';
const SPIN_TO_WIN_BLOCK_STYLE_ELEMENTS = Object.assign(Object.assign(Object.assign(Object.assign({}, BLOCK_STYLE_ELEMENT), SPIN_TO_WIN_INNER_CIRCLE_ELEMENT), SPIN_TO_WIN_LINE_ELEMENT), SPIN_TO_WIN_OUTER_CIRCLE_ELEMENT);
// TODO(spin): maybe make an element for the line container to normalize style definitions or do away with the container
// TODO(spin): see if we can use svg lines instead of divs, but still get the same css style support
const defaultStyles = {
    [SPIN_TO_WIN_BLOCK_STYLE_ELEMENTS.BLOCK]: {
        mobile: {
            // private
            [STYLE_RULE_NAMES.POSITION]: 'relative',
        },
    },
    [SPIN_TO_WIN_BLOCK_STYLE_ELEMENTS.SPIN_TO_WIN_INNER_CIRCLE]: {
        mobile: {
            // private
            [STYLE_RULE_NAMES.BACKGROUND_COLOR]: '#333333',
            [STYLE_RULE_NAMES.BORDER_COLOR]: '#FFFFFF',
            [STYLE_RULE_NAMES.BORDER_RADIUS]: '50%',
            [STYLE_RULE_NAMES.BORDER_STYLE]: 'solid',
            [STYLE_RULE_NAMES.BORDER_WIDTH]: '2px',
            [STYLE_RULE_NAMES.HEIGHT]: '100px',
            [STYLE_RULE_NAMES.INSET]: '50% auto auto 50%',
            [STYLE_RULE_NAMES.TRANSFORM]: 'translate(-50%, -50%)',
            [STYLE_RULE_NAMES.WIDTH]: '100px',
            [STYLE_RULE_NAMES.POSITION]: 'absolute',
        },
    },
    [SPIN_TO_WIN_BLOCK_STYLE_ELEMENTS.SPIN_TO_WIN_LINE]: {
        mobile: {
            // private
            [STYLE_RULE_NAMES.BORDER_TOP_COLOR]: '#000000',
            [STYLE_RULE_NAMES.BORDER_TOP_STYLE]: 'solid',
            [STYLE_RULE_NAMES.BORDER_TOP_WIDTH]: '2px',
            [STYLE_RULE_NAMES.INSET]: '50% auto auto 50%',
            [STYLE_RULE_NAMES.POSITION]: 'absolute',
            // TODO(spin): we might not need this
            [STYLE_RULE_NAMES.TRANSFORM_ORIGIN]: '0 0',
            [STYLE_RULE_NAMES.WIDTH]: '50%',
        },
    },
    [SPIN_TO_WIN_BLOCK_STYLE_ELEMENTS.SPIN_TO_WIN_OUTER_CIRCLE]: {
        mobile: {
            // private
            [STYLE_RULE_NAMES.BORDER_COLOR]: '#FFFFFF',
            [STYLE_RULE_NAMES.BORDER_RADIUS]: '50%',
            [STYLE_RULE_NAMES.BORDER_STYLE]: 'solid',
            [STYLE_RULE_NAMES.BORDER_WIDTH]: '1px',
            [STYLE_RULE_NAMES.INSET]: '0px 0px 0px 0px',
            [STYLE_RULE_NAMES.POSITION]: 'absolute',
        },
    },
};
const addBlockActions = ({ block: { id }, blockActions, popupActions, undeletableIds, }) => {
    if (!(undeletableIds === null || undeletableIds === void 0 ? void 0 : undeletableIds.includes(id)))
        blockActions.deleteBlock = () => { var _a; return (_a = popupActions.deleteBlock) === null || _a === void 0 ? void 0 : _a.call(popupActions, id); };
};
const DIAMETER = 100;
const RADIUS = DIAMETER / 2;
const HALF_RADIUS = RADIUS / 2;
const CIRCUMFERENCE = 2 * Math.PI * HALF_RADIUS;
const COLORS = [
    'purple',
    'red',
    'green',
    'blue',
    'cyan',
    'orange',
    'pink',
    'yellow',
];
const getLines = (colors, cssClass) => {
    const total = colors.length;
    return colors.map((_, index) => html `
      <div
        class=${classMap(cssClass)}
        style=${`transform: rotate(${-90 + (360 / total) * index}deg) translate(0, -50%);`}
      />
    `);
};
const getCircles = (colors) => {
    const total = colors.length;
    const strokeDasharray = `${((100 / total) * CIRCUMFERENCE) / 100} ${CIRCUMFERENCE}`;
    return colors.map((color, index) => svg `
      <circle
        cx=${RADIUS}
        cy=${RADIUS}
        r=${HALF_RADIUS}
        fill="transparent"
        stroke=${color}
        stroke-width=${RADIUS}
        stroke-dasharray=${strokeDasharray}
        transform=${`rotate(${-90 + (360 / total) * index}, ${RADIUS}, ${RADIUS})`}
      />
    `);
};
function render(renderData) {
    const { blockActions, classes, environment } = renderData;
    const { block: blockClasses, spinToWinLine: spinToWinLineClasses, spinToWinInnerCircle: spinToWinOuterInnerClasses, spinToWinOuterCircle: spinToWinOuterCircleClasses, editorNode: editorNodeClasses, } = classes;
    return html `
    <div
      class=${classMap(blockClasses)}
      draggable=${ifDefined(environment.isDevelopment ? 'true' : undefined)}
      @click=${ifDefined(blockActions.selectBlock)}
      @dragstart=${ifDefined(blockActions.handleReorderDragStart)}
      @dragend=${ifDefined(blockActions.handleReorderDragEnd)}
      @drop=${ifDefined(blockActions.handleReorderDrop)}
    >
      ${environment.isDevelopment && blockActions.deleteBlock
        ? renderEditorNode(editorNodeClasses, blockActions.deleteBlock)
        : nothing}
      ${svg `
          <svg
            viewBox="0 0 ${DIAMETER} ${DIAMETER}"
            xmlns="http://www.w3.org/2000/svg"
          >
            ${getCircles(COLORS)}
          </svg>
        `}
      <div style="position: absolute; inset: 0;">
        ${getLines(COLORS, spinToWinLineClasses)}
      </div>
      <div class=${classMap(spinToWinOuterCircleClasses)}></div>
      <div class=${classMap(spinToWinOuterInnerClasses)} /></div>
    </div>
  `;
}
const spinToWinBlockHelpers = {
    addBlockActions,
    defaultStyles,
    render,
    styleElements: SPIN_TO_WIN_BLOCK_STYLE_ELEMENTS,
};
export default spinToWinBlockHelpers;
