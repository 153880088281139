// Id's are needed to increase order of precendence because Shopify themes may override them.

/**
 * @typedef {object} DesktopStyleProps
 * @prop {string} headlineColor
 * @prop {string} disclaimerTextColor
 * @prop {boolean} fullscreen
 */

/**
 * @param {import("../../widget").PopupPreferences & DesktopStyleProps} props
 */

// TODO(Kameron Ahler): 100vh on desktop (truly desktop), will be fine, but will
// overflow on mobile
const desktopStyles = ({
  customCss,
  font,
  backgroundColor,
  backgroundImage,
  backgroundStyle,
  headlineColor,
  exitButtonColor,
  borderRadius,
  buttonRadius,
  buttonBackgroundColor,
  buttonTextColor,
  fullscreen,
  disclaimerTextColor,
}) => `
  /* Modal styles */
  #ps-bis-widget-modal__container.ps-desktop-modal__container {
    border: none;
    position: fixed;
    visibility: visible;
    z-index: 2147483647;
    max-height: 100vh;
    max-width: 100vw;
    transition: none 0s ease 0s;
    background: none transparent;
    opacity: 1;height: 100%;
    width: 100%;
    inset: 0px auto auto 0px;
    display: block;
  }

  #ps-bis-widget-modal__container .ps-desktop-modal__background {
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  #ps-bis-widget-modal__container .ps-desktop-modal__click-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
  }
  
  #ps-bis-widget-modal__container .ps-desktop-modal__body {
    width:auto;
    height: auto;
    max-width: 600px;
    margin: 20px;
    padding: 30px 5% 50px;
    z-index: 10;
    box-sizing: border-box;
    position: relative;
    background-size: cover;
    background-position: center;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    word-break: break-word;
    flex-grow: 1;
  }


  #ps-desktop-widget__logo-image {
    max-height: 100px;
    max-width: 100%;
  }

  #ps-bis-widget-modal__container #ps-desktop-widget__close {
    cursor: pointer;
    position: absolute;
    padding: 0;
    margin: 0;
    top: 20px;
    right: 20px;
    background: none;
    border: none;
    font-weight: 300;
    font-size: 30px;
  }
  
  #ps-bis-widget-modal__container #ps-desktop-widget__close > svg {
    display: block;
    width: 24px;
  }

  #ps-bis-widget-modal__container #ps-desktop-widget__phone-input-wrapper {
    background-color: rgba(255,255,255,.3);
  }
  
  #ps-bis-widget-modal__container #ps-desktop-widget__phone-input-wrapper > .iti {
    width: 100%;
    font-weight: bold;
  }
  
  #ps-bis-widget-modal__container #ps-desktop-widget__phone-input-wrapper > .iti--separate-dial-code .iti__selected-flag {
    background-color: rgba(0, 0, 0, 0);
  }

  #ps-bis-widget-modal__container #ps-bis-widget-modal__phone-input {
    font-size: 18px;
    width: 100%;
    background-color: rgba(0, 0, 0, 0);
    padding: 8px;
    padding-top: 6px;
    padding-left: 76px !important;
    width: 100%;
    outline: 0;
    border: 1px solid #9ba6b6;
  }

  #ps-bis-widget-modal__container #ps-desktop-widget__disclaimer-text {
    text-align: left;
    font-size: 11px;
    line-height: 13px;
    margin: 24px 0;
    letter-spacing: normal;
  }

  #ps-desktop-widget__submit {
    cursor: pointer;
    font-size: 18px;
    width: 100%;
    border: 0;
    padding: 12px;
  }

  #ps-desktop-widget__close-link {
    cursor: pointer;
    font-size: 18px;
    border: 0;
    background-color: rgba(0, 0, 0, 0);
    margin: 16px;
  }

  #ps-bis-widget-modal__container .ps-heading {
    font-size: 5.9rem;
    margin: 0px;
    font-weight: bold;
  }

  #ps-bis-widget-modal__container .ps-section {
    margin: 15px 0px 0px;
    color: ${headlineColor};
  }
  
  #ps-bis-widget-modal__container .ps-p {
    margin: 0;
    font-size: 1.9rem;
    font-weight: 400
  }

  #ps-bis-widget-modal__container div:empty {
    display: inherit;
  }

  /* Dynamic styles */
  ${customCss}

  #ps-bis-widget-modal__container,
  #ps-bis-widget-modal__container button,
  #ps-bis-widget-modal__container input,
  #ps-bis-widget-modal__container .ps-heading,
  #ps-bis-widget-modal__container #ps-desktop-widget__phone-input-wrapper,
  #ps-desktop-widget__disclaimer-text
  {
    font-family: ${font.split(':')[0]}, sans-serif !important;
  }

  #ps-bis-widget-modal__body-view {
    max-width: ${fullscreen ? '600px' : 'none'};
  }

  #ps-bis-widget-modal__body.ps-desktop-modal__body {
    border-radius: ${fullscreen ? 0 : borderRadius}px;
    background-color: ${backgroundColor};
    background-image: ${
      backgroundStyle === 'Image' ? `url('${backgroundImage}')` : 'none'
    };
    width: ${fullscreen ? '100%' : 'auto'};
    height: ${fullscreen ? '100%' : 'auto'};
    max-width: ${fullscreen ? 'none' : '600px'};
    margin: ${fullscreen ? '0px' : '20px'};
    padding: ${fullscreen ? '0 10% 15vh 10%;' : '30px 5% 50px'};

    ${
      fullscreen
        ? `
      display: flex;
      justify-content: center;
    `
        : ''
    }
  }

  #ps-desktop-widget__close > svg {
    fill: ${exitButtonColor};
  }

  #ps-desktop-widget__submit {
    border-radius: ${buttonRadius}px;
    background-color: ${buttonBackgroundColor};
    color: ${buttonTextColor};
  }

  #ps-desktop-widget__close-link {
    color: ${buttonBackgroundColor};
  }

  #ps-desktop-widget__disclaimer-text a {
    color: ${disclaimerTextColor};
    cursor: pointer;
    display: inline-block;
    text-transform: uppercase;
    text-decoration: underline;
  }

  #ps-desktop-widget__disclaimer-text {
    color: ${disclaimerTextColor}
  }
`;

export default desktopStyles;
